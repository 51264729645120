import React from 'react';
import { useSelector } from 'react-redux';

function SessionEndedModal({ isOpen, onClose}) {
  const language = useSelector((state) => state.language.active_language);
  
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="form-bottom-button-wrapper">
            <p>{language.session_expired}</p>
            <div className="button-wrapper">
                <button className="button button-blue" onClick={onClose}>
                {language.go_to_login}
                </button>
            </div>
        </div>
        
      </div>
    </div>
  );
}

export default SessionEndedModal;
