import React from 'react';
// import general layout components
import LeftPanel from '../Layouts/LeftPanel';
import RightPanel from '../Layouts/RightPanel';
import LanguageSelector from '../LanguageSelector';
import CardCentered from '../CardCentered';

// start imports of all of the options in the training
import TrainingLobby from '../TrainingSignup/TrainingLobby';
import IsThisYou from '../TrainingSignup/IsThisYou';
import TraineeInformation from '../TrainingSignup/TraineeInformation';
import WhichTraineeAreYou from '../TrainingSignup/WhichTraineeAreYou';
import LocationSelection from '../LocationSelection';
import TraineeInfoForm from '../TrainingSignup/TraineeInfoForm';
import { useSelector } from 'react-redux';

function renderCorrectComponent(current_page) {
  switch (current_page) {
    case 'training-lobby':
      return <TrainingLobby />;
    case 'location-selection':
      return <LocationSelection />;
    case 'is-this-you':
      return <IsThisYou />;
    case 'which-trainee-are-you':
      return <WhichTraineeAreYou />;
    case 'trainee-information':
      return <TraineeInformation />;
    case 'returning-trainee-information':
      return <TraineeInfoForm />;
    default:
      return 'something went wrong - training display';
  }
}

function TrainingDisplay() {
  const current_page = useSelector((state) => state.page.screen);

  return (
    <div className="bg-topo-dark">
      <LeftPanel />
      <RightPanel className="bg-white">
        <LanguageSelector className="language-selector text-navy" />
        <CardCentered>{renderCorrectComponent(current_page)}</CardCentered>
      </RightPanel>
    </div>
  );
}

export default TrainingDisplay;
