import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active_language_code: 'en',
  active_language: {
    welcome_text: 'Welcome to',
    welcome_text_2: 'Site-Specific safety training.',
    bottom_text:
      'All contractors and visitors at this location must complete the training once per calendar year',
  },
  connectionResponse: '',
  languages_list: [],
  loading: true,
  initialized: false, // Add this flag
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setActiveLanguage: (state, action) => {
      if (!action.payload) {
        console.warn('setActiveLanguage: No language code provided');
        return;
      }

      const languageCode = action.payload.toLowerCase();

      // Ensure languages_list exists and is an array
      if (!Array.isArray(state.languages_list)) {
        state.languages_list = [];
        console.warn(
          'languages_list was not an array, initialized as empty array'
        );
      }

      const languageData = state.languages_list.find(
        (lang) => lang.code?.toLowerCase() === languageCode
      );

      if (languageData?.data) {
        state.active_language = languageData.data;
        state.active_language_code = languageCode;
      } else {
        console.warn(`Language data for code '${languageCode}' not found.`);
      }
    },

    addNewLanguage: (state, action) => {
      // Ensure languages_list exists and is an array
      if (!Array.isArray(state.languages_list)) {
        state.languages_list = [];
      }

      const new_lang = action.payload;
      if (!new_lang?.code) return;

      // Only add if it doesn't exist
      if (!state.languages_list.some((lang) => lang.code === new_lang.code)) {
        state.languages_list.push({
          code: new_lang.code,
          name: new_lang.title,
          data: new_lang.data,
        });
      }
    },

    setLanguageConnectionResponse: (state, action) => {
      state.connectionResponse = action.payload || '';
    },

    setLanguagesLoaded: (state) => {
      state.loading = false;
      state.initialized = true;
    },

    resetLanguageState: () => ({
      ...initialState,
      loading: true,
      initialized: false,
    }),
  },
});

export const {
  setActiveLanguage,
  addNewLanguage,
  setLanguageConnectionResponse,
  resetLanguageState,
  setLanguagesLoaded,
} = languageSlice.actions;

export default languageSlice.reducer;
