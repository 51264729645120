import React from 'react';
import { useSelector } from 'react-redux';

function SkipVideoModal({ isOpen, playVideo, skipVideo }) {
  const language = useSelector((state) => state.language.active_language);

  if (!isOpen) return null;
  

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="form-bottom-button-wrapper">
            <p>{language.skip_video_text}</p>
            <div className="button-wrapper">
                <button className="button button-blue" onClick={playVideo}>
                {language.skip_video_no}
                </button>
                <button className="button button-blue" onClick={skipVideo}>
                {language.skip_video_yes}
                </button>
            </div>
        </div>
        
      </div>
    </div>
  );
}

export default SkipVideoModal;