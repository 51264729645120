import * as ActionTypes from '../ActionTypes';
import {
  ForgotPasswordService,
  ResetPasswordService,
} from '../../services/AuthServices';
import { setAllUserInfo } from '../reducers/UserReducer';
import { setAllLocationInfo } from '../reducers/LocationReducer';
import { setAllCompanyInfo } from '../reducers/CompanyReducer';
import { GetCompanyService } from '../../services/CompanyServices';
import { GetLocationService } from '../../services/LocationsServices';
import { GetLanguagesService } from '../../services/LanguageServices';
import { setScreen } from '../reducers/PageReducer';
import { persistor, store } from '../Store';
import { resetCompanyState } from '../reducers/CompanyReducer';
import { resetLanguageState } from '../reducers/LanguageReducer';
import { resetLocationState } from '../reducers/LocationReducer';
import { resetPageState } from '../reducers/PageReducer';
import { resetTraineesState } from '../reducers/TraineesReducer';
import { resetUserState } from '../reducers/UserReducer';
import {
  setActiveLanguage,
  addNewLanguage,
  setLanguagesLoaded,
} from '../reducers/LanguageReducer';

// Inside your AuthActions.js, modify the loadLanguages function
const loadLanguages = async (dispatch, locationData) => {
  try {
    console.log('Starting language load with location data:', locationData);
    const languages = await GetLanguagesService();
    console.log('Languages received from service:', languages);

    if (languages && Array.isArray(languages)) {
      console.log('Adding languages to store...');
      // Add each language to the store
      for (const lang of languages) {
        console.log('Adding language:', lang);
        dispatch(addNewLanguage(lang));
      }

      // Set active language based on location preference
      const preferredLanguage = locationData?.preferred_language || 'en';
      console.log('Setting active language to:', preferredLanguage);
      dispatch(setActiveLanguage(preferredLanguage));
    } else {
      console.warn('No valid languages received from service');
    }
  } catch (error) {
    console.error('Error loading languages:', error);
  } finally {
    console.log('Marking languages as loaded');
    dispatch(setLanguagesLoaded());
  }
};

export const LoginAction = (user, history) => {
  return async (dispatch) => {
    console.log('LoginAction started with user:', user);

    try {
      dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
      dispatch({ type: ActionTypes.LOADING });
      console.log('Initial dispatches complete');

      // Reset states
      dispatch(resetCompanyState());
      dispatch(resetLanguageState());
      dispatch(resetLocationState());
      dispatch(resetPageState());
      dispatch(resetTraineesState());
      dispatch(resetUserState());
      console.log('States reset complete');

      dispatch({ type: ActionTypes.LOGIN_SUCCESS });
      dispatch(setAllUserInfo(user));
      console.log('User info set');

      if (user.location_id?.includes(',')) {
        console.log('Multiple locations detected');
        dispatch(setScreen('location-selection'));
        history('/location-selection');
        return;
      }

      console.log('Fetching location and company data...');
      const [locationData, companyData] = await Promise.all([
        GetLocationService(`id/${user.location_id}`),
        GetCompanyService(`id/${user.company_id}`),
      ]);

      console.log('Location data:', locationData);
      console.log('Company data:', companyData);

      if (locationData) {
        dispatch(setAllLocationInfo(locationData));
        console.log('Location info set');
        await loadLanguages(dispatch, locationData);
        console.log('Languages loaded');
      }

      if (companyData) {
        dispatch(setAllCompanyInfo(companyData));
        console.log('Company info set');
      }

      dispatch(setScreen('training-lobby'));
      history('/training-lobby');
      console.log('Navigation complete');
    } catch (error) {
      console.error('LoginAction error:', error);
      dispatch({ type: ActionTypes.CODE_ERROR, error });
    }
  };
};

export const LogoutAction = () => {
  return async (dispatch) => {
    try {
      // Reset all states
      dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
      dispatch(resetCompanyState());
      dispatch(resetLanguageState());
      dispatch(resetLocationState());
      dispatch(resetPageState());
      dispatch(resetTraineesState());
      dispatch(resetUserState());

      // Dispatch logout action
      store.dispatch({ type: 'LOGOUT' });

      // Clear persisted state
      await persistor.purge();

      // Clear local storage
      localStorage.removeItem('user-token');
      localStorage.removeItem('expires-in-unix-time');
    } catch (error) {
      console.error('Logout error:', error);
      // Even if there's an error, we still want to clear states
      dispatch({ type: 'LOGOUT' });
    }
  };
};

export const CheckTokenAction = (history) => {
  return (dispatch) => {
    const expires = parseInt(localStorage.getItem('expires-in-unix-time'), 10);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    console.log('expires in:', expires);
    console.log('current time:', currentTimeInSeconds);

    if (expires < currentTimeInSeconds) {
      dispatch(LogoutAction());
    }
  };
};

export const ForgotPasswordAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    ForgotPasswordService(credentials).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS, res });
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch({ type: ActionTypes.FORGOT_PASSWORD_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const ResetPasswordAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    ResetPasswordService(credentials).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_SUCCESS, res });
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
