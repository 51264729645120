import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { setScreen } from '../../redux/reducers/PageReducer';
import { removeActiveTrainee, addTrainee } from '../../redux/reducers/TraineesReducer';

export default function TraineeInfoForm() {
    const dispatch = useDispatch();
    
    // Extract necessary state values
    const language = useSelector((state) => state.language.active_language);
    const active_trainee = useSelector((state) => state.trainees.active_trainee);
    const location = useSelector((state) => state.location);
    const company = useSelector((state) => state.company);

    // Initialize the form with default values
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            first_name: active_trainee.first_name || '',
            last_name: active_trainee.last_name || '',
            email: active_trainee.email || '',
            phone: active_trainee.phone || '',
            company: active_trainee.company || '',
            visitor_type: active_trainee.visitor_type || '',
            msha_number: active_trainee.msha_number || '',
            preferred_language: active_trainee.preferred_language || '',
        },
    });



    // Handle "Go Back" button click
    const goBack = (e) => {
        e.preventDefault(); // Prevent form submission
        dispatch(removeActiveTrainee());
        dispatch(setScreen('training-lobby'));
    };



    // Handle form submission
    const onSubmit = (data) => {
        const updatedTrainee = {
            ...active_trainee,
            ...data, // Merge form data with active trainee data
            location_id: active_trainee.location_id || location.id,
            company_id: active_trainee.company_id || company.id,
            region_id: active_trainee.region_id || location.region_id,
            checked_in: active_trainee.checked_in || false,
            company: data.company || 'none', // Use data from the form, default to 'none' if empty
            preferred_language: data.preferred_language || location.preferred_language,
        };

        // Dispatch the action to update trainee
        //dispatch(UpdateSingleTraineeAction(updatedTrainee));

        // if the trainee doesn't have an id, its a new trainee. wee need to give it a unique random negative temp id for now
        if (!updatedTrainee.id) {
            updatedTrainee.id = -1 * Math.floor(Math.random() * 1000000);
        }

        // add trainee to the list of trainees
        dispatch(addTrainee(updatedTrainee));
        // clear active trainee
        dispatch(removeActiveTrainee());
        // move back to training lobby
        dispatch(setScreen('training-lobby'));

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* First and Last Name Fields */}
            <div className="row">
                <div className={`half-width ${errors.first_name ? 'has-error' : ''}`}>
                    <label htmlFor="first_name">{language.form_first_name} *</label>
                    <input
                        type="text"
                        id="first_name"
                        {...register('first_name', {
                            required: language.required_first_name,
                            minLength: { value: 3, message: language.required_first_name_min },
                            maxLength: { value: 65, message: language.required_first_name_max },
                        })}
                    />
                    {errors.first_name && <p className="error-message">{errors.first_name.message}</p>}
                </div>

                <div className={`half-width ${errors.last_name ? 'has-error' : ''}`}>
                    <label htmlFor="last_name">{language.form_last_name} *</label>
                    <input
                        type="text"
                        id="last_name"
                        {...register('last_name', {
                            required: language.required_last_name,
                            minLength: { value: 3, message: language.required_last_name_min },
                            maxLength: { value: 65, message: language.required_last_name_max },
                        })}
                    />
                    {errors.last_name && <p className="error-message">{errors.last_name.message}</p>}
                </div>
            </div>

            {/* Email and Phone Fields */}
            <div className="row">
                <div className={`half-width ${errors.email ? 'has-error' : ''}`}>
                    <label htmlFor="email">{language.form_email}</label>
                    <input
                        type="email"
                        id="email"
                        {...register('email', {
                            minLength: { value: 9, message: language.required_email_min },
                            maxLength: { value: 65, message: language.required_email_max },
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[^\s@]+)*$/,
                                message: language.required_email_invalid,
                            },
                        })}
                    />
                    {errors.email && <p className="error-message">{errors.email.message}</p>}
                </div>

                <div className={`half-width ${errors.phone ? 'has-error' : ''}`}>
                    <label htmlFor="phone">{language.form_phone} *</label>
                    <input
                        type="tel"
                        id="phone"
                        {...register('phone', {
                            required: language.required_phone,
                            minLength: { value: 10, message: language.required_phone_min },
                            maxLength: { value: 18, message: language.required_phone_max },
                        })}
                    />
                    {errors.phone && <p className="error-message">{errors.phone.message}</p>}
                </div>
            </div>

            {/* Company and Visitor Type Fields */}
            <div className="row">
                <div className={`half-width ${errors.company ? 'has-error' : ''}`}>
                    <label htmlFor="company">{language.form_company} *</label>
                    <input
                        type="text"
                        id="company"
                        {...register('company', {
                            required: language.required_company,
                            minLength: { value: 3, message: language.required_company_min },
                            maxLength: { value: 65, message: language.required_company_max },
                        })}
                    />
                    {errors.company && <p className="error-message">{errors.company.message}</p>}
                </div>

                <div className={`half-width ${errors.visitor_type ? 'has-error' : ''}`}>
                    <label htmlFor="visitor_type">{language.form_visitor_type} *</label>
                    <select id="visitor_type" {...register('visitor_type', { required: language.required_visitor_type })}>
                        <option value="">{language.form_visitor_type_please_select}</option>
                        <option value="contractor">{language.form_visitor_type_contractor}</option>
                        {/* <option value="truck">{language.form_visitor_type_vendor}</option> */}
                        <option value="truck">{language.form_visitor_type_truck}</option>
                        <option value="guest">{language.form_visitor_type_guest}</option>
                    </select>
                    {errors.visitor_type && <p className="error-message">{errors.visitor_type.message}</p>}
                </div>
            </div>

            {/* MSHA Number and Preferred Language */}
            <div className="row">
                <div className={`half-width ${errors.msha_number ? 'has-error' : ''}`}>
                    <label htmlFor="msha_number">{language.form_msha_number}</label>
                    <input
                        type="text"
                        id="msha_number"
                        {...register('msha_number', {
                            minLength: { value: 3, message: language.required_msha_min },
                            maxLength: { value: 65, message: language.required_msha_max },
                        })}
                    />
                    {errors.msha_number && <p className="error-message">{errors.msha_number.message}</p>}
                </div>

                <div className={`half-width ${errors.preferred_language ? 'has-error' : ''}`}>
                    <label htmlFor="preferred_language">{language.form_preferred_lang} *</label>
                    <select id="preferred_language" {...register('preferred_language', { required: language.required_lang_choice })}>
                        <option value="">{language.form_preferred_lang_please_select}</option>
                        <option value="en">{language.form_preferred_lang_en}</option>
                        <option value="es">{language.form_preferred_lang_es}</option>
                    </select>
                    {errors.preferred_language && (
                        <p className="error-message">{errors.preferred_language.message}</p>
                    )}
                </div>
            </div>

            {/* Buttons */}
            <div className="form-bottom-button-wrapper">
                <button className="button button-blue" onClick={goBack}>{language.go_back_button}</button>
                <button type="submit" className="button button-blue">{language.continue_button}</button>
            </div>
        </form>
    );
}
