import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginAction } from '../../redux/actions/AuthActions';
import { fetchUserAttributes } from '@aws-amplify/auth';
import TrainingDisplay from '../Displays/TrainingDisplay';
import VideoDisplay from '../Displays/VideoDisplay';
import ReviewDisplay from '../Displays/ReviewDisplay';
import Loader from '../Loader';
// Import all your styles
import '../../assets/style/components/buttons.scss';
import '../../assets/style/components/centered-card.scss';
import '../../assets/style/components/forms.scss';
import '../../assets/style/components/loader.scss';
import '../../assets/style/components/review-card.scss';
import '../../assets/style/pages/agreement.scss';
import '../../assets/style/pages/finished.scss';
import '../../assets/style/global-styles.scss';
import '../../assets/style/pages/is-this-you.scss';
import '../../assets/style/pages/new-trainee-register.scss';
import '../../assets/style/pages/review-training-info.scss';
import '../../assets/style/pages/signature-agreement.scss';
import '../../assets/style/pages/start-training.scss';
import '../../assets/style/pages/training-lobby.scss';
import '../../assets/style/pages/video-section.scss';

// If you have a training lobby display component, import it here. For now, let's just return a placeholder:
function TrainingLobbyDisplay() {
  return <div>Welcome to the Training Lobby</div>;
}

function LocationSelectionDisplay() {
  return <div>Please select your location.</div>;
}

function renderCorrectComponent(current_display) {
  switch (current_display) {
    case 'training':
      return <TrainingDisplay />;
    case 'video':
      return <VideoDisplay />;
    case 'review':
      return <ReviewDisplay />;
    case 'training-lobby':
      return <TrainingLobbyDisplay />;
    case 'location-selection':
      return <LocationSelectionDisplay />;
    default:
      return 'Something went wrong - single page';
  }
}

function formatUserAttributes(userAttributes) {
  return Object.entries(userAttributes).reduce((acc, [key, value]) => {
    if (key.startsWith('custom:')) {
      acc[key.replace('custom:', '')] = value;
    } else {
      acc[key] = value;
    }
    console.log(acc);
    return acc;
  }, {});
}

function Main({ user, loadedUser, setLoadedUser }) {
  const current_display = useSelector((state) => state.page?.display);
  const languageState = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasInitialized, setHasInitialized] = useState(false); // Add this flag

  // Debug: Log whenever user or loadedUser changes
  useEffect(() => {
    console.log('User or loadedUser changed:', {
      hasUser: !!user,
      loadedUser,
      userDetails: user,
    });
  }, [user, loadedUser]);

  // Debug: Log language state changes
  useEffect(() => {
    console.log('Language state:', {
      loading: languageState?.loading,
      languageCount: languageState?.languages_list?.length,
      activeLanguage: languageState?.active_language_code,
    });
  }, [languageState]);

  useEffect(() => {
    // Changed condition to check for languages and hasInitialized
    if (!user || hasInitialized || languageState?.languages_list?.length > 0) {
      console.log('Skipping initialization:', {
        hasUser: !!user,
        hasInitialized,
        languagesLoaded: languageState?.languages_list?.length > 0,
      });
      return;
    }

    const initializeUser = async () => {
      try {
        console.log('Starting user initialization');
        setIsLoading(true);

        const userAttributes = await fetchUserAttributes();
        console.log('Fetched user attributes:', userAttributes);

        const formatted = formatUserAttributes(userAttributes);
        console.log('Formatted attributes:', formatted);

        console.log('Dispatching LoginAction...');
        await dispatch(LoginAction(formatted, history));
        console.log('LoginAction completed');

        setLoadedUser(true);
        setHasInitialized(true); // Mark initialization as complete
        console.log('User marked as loaded');
      } catch (error) {
        console.error('Initialization error:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
        console.log('Initialization completed');
      }
    };

    console.log('Starting initialization process...');
    initializeUser();
  }, [
    user,
    loadedUser,
    languageState?.languages_list?.length,
    hasInitialized,
    dispatch,
    history,
    setLoadedUser,
  ]);

  const isPageLoading = isLoading || !languageState?.languages_list?.length;

  if (isPageLoading) {
    return <Loader />;
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="main-container">
      {renderCorrectComponent(current_display)}
    </div>
  );
}

export default Main;
