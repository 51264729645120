import * as ActionTypes from '../ActionTypes';
import Loader from '../../components/Loader';

const initState = {
  authResponse: '',
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.RESTART_AUTH_RESPONSE:
      return {
        ...state,
        authResponse: '',
      };
    case ActionTypes.LOADING:
      return {
        ...state,
        authResponse: <Loader />,
      };

    case ActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        authResponse: action.res,
      };

    case ActionTypes.SIGNUP_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };

    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        authResponse: 'redirecting to dashboard...',
      };

    case ActionTypes.LOGIN_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };

    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        authResponse: action.res,
      };

    case ActionTypes.LOGOUT_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };

    // FORGOT PASSWORD
    case ActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        authResponse: action.res,
      };

    case ActionTypes.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };

    // PASSWORD RESET
    case ActionTypes.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        authResponse: action.res,
      };

    case ActionTypes.PASSWORD_RESET_ERROR:
      return {
        ...state,
        authResponse: action.res,
      };

    case ActionTypes.CODE_ERROR:
      return {
        ...state,
        authResponse:
          'There seems to be a problem, please refresh your browser',
      };

    default:
      return state;
  }
};

export default AuthReducer;
