import { useEffect } from 'react';
import { LogoutAction } from '../../redux/actions/AuthActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Logout({ signOut }) {
  const nav = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Logging out should update the store and then call signOut
    dispatch(LogoutAction());
    signOut();
    nav('/');
  }, [dispatch, nav]);

  return null;
}
