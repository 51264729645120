import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import { setBoth } from '../../redux/reducers/PageReducer';
import pauseButton from '../../assets/images/Pause_button_white.png';
import playButton from '../../assets/images/Play_button_white.png';
import rewindButton from '../../assets/images/10_Sec_rewind_white.png';
import Duration from '../TrainingVideo/Duration';
import VideoEndOverlay from '../TrainingVideo/VideoEndOverlay';
import VideoWelcomeOverlay from '../TrainingVideo/VideoWelcomeOverlay';
import VideoPauseOverlay from '../TrainingVideo/VideoPauseOverlay';
import { useEffect } from 'react';
/*
    !!! the progress bar and counter in the display will not work with strict mode on (dev only) !!!
    - to adjust this, head over to index.js
*/

function VideoDisplay() {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.active_language);
    const lang_code = useSelector((state) => state.language.active_language_code);
    const location = useSelector((state) => state.location);
    const trainees_list = useSelector((state) => state.trainees.trainee_list);
    const [video_url, setVideoUrl] = useState('https://videotervuren.s3.us-east-2.amazonaws.com/Tervuren-Demo-Video.mp4');
          

    /**
     * The function's goal is to select the most appropriate video to show to a group of trainees based on 
     * their roles and preferred language. It ensures that trainees receive the most relevant and understandable 
     * training content available.
     * 
     *  Priority Order: These roles have a priority from most important to least:
     *      1. Guest (highest priority)
     *      2. Contractor
     *      3. Truck Driver
     *      4. Main (general/default)
     * 
     * If a specific video isn't available, it automatically moves to the next option.
     * 
     * If no spanish, it defaults to the english version
     */
    const determineVideoUrl = (videoData) => {
      
        // Define the priority order
        const priorityOrder = ['guest', 'contractor', 'truck', 'main'];
      
        // Get the list of visitor types present in the trainees list
        const visitorTypesPresent = trainees_list.map((trainee) =>
          trainee.visitor_type.trim().toLowerCase()
        );
      
        // Determine the highest priority visitor type present
        let visitorType = 'main'; // default
        for (let type of priorityOrder) {
          if (visitorTypesPresent.includes(type)) {
            visitorType = type;
            break;
          }
        }

        // Construct the array of possible video keys in order of preference
        // Initialize the possibleVideoKeys array
        const possibleVideoKeys = [];

        // Add the video key for the preferred language
        possibleVideoKeys.push(`${visitorType}_${lang_code}`);

        // Add fallback to English if the preferred language is not English
        if (lang_code !== 'en') {
            possibleVideoKeys.push(`${visitorType}_en`);
        }

        // Add the main video in the preferred language
        possibleVideoKeys.push(`main_${lang_code}`);

        // Add fallback to main English video if the preferred language is not English
        if (lang_code !== 'en') {
            possibleVideoKeys.push(`main_en`);
        }
      
        // Try each key until we find a video URL
        let videoUrl = null;
        for (let key of possibleVideoKeys) {
          videoUrl = videoData[key];
          if (videoUrl) {
            break;
          }
        }
      
        // If still not available, use the default video URL
        if (!videoUrl) {
          videoUrl = 'https://videotervuren.s3.us-east-2.amazonaws.com/Tervuren-Demo-Video.mp4';
        }
      
        console.log('Selected video URL:', videoUrl);
        return videoUrl;
      };
      


    // Set the video URL based on the location and trainees list
    useEffect(() => {
        
        let videoUrl = null;
    
        // If location.video is a string
        if (typeof location.video === 'string') {
        try {
            // Try to parse it as JSON
            const videoData = JSON.parse(location.video);
    
            // Parsing succeeded, proceed with the logic using videoData
            videoUrl = determineVideoUrl(videoData);
        } catch (e) {
            // Parsing failed, so location.video is a legacy video URL
            // Use the location.video as the video URL directly
            videoUrl = location.video;
        }
        } else if (typeof location.video === 'object' && location.video !== null) {
            // location.video is already an object
            videoUrl = determineVideoUrl(location.video);
        } else {
            // location.video is not a string or object, use default video URL
            videoUrl = 'https://videotervuren.s3.us-east-2.amazonaws.com/Tervuren-Demo-Video.mp4';
        }

        // Set the video URL
        setVideoUrl(videoUrl);

        // eslint-disable-next-line
    }, [location, trainees_list, lang_code]);
      


  // Initialize state for showing welcome, pause and end overlay
  const [activeOverlay, setActiveOverlay] = useState('welcome'); // 'welcome', 'pause', or 'end'


  // video control data
  const [isPlaying, setIsPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [isMuted] = useState(false);
  const video_player = useRef(null);
  const [duration, setDuration] = useState(0);

  const returnToLobby = () => {
    setActiveOverlay(null);
    dispatch(setBoth({ display: 'training', screen: 'training-lobby' }));
 };

  // start the video and hide welcome overlay
  const startVideo = () => {
    setActiveOverlay(null);
    setIsPlaying(true);
  };

  // handle restart video. need to hide panel as well
  const restartVideo = () => {
    setActiveOverlay(null);
    video_player.current.seekTo(0);
    setIsPlaying(true);
  };

  // handle pause button
  const handlePause = (e) => {
    setIsPlaying(false);
    setActiveOverlay('pause');
  };

  // handle play button
  const handlePlay = () => {
    setIsPlaying(true);
    setActiveOverlay(null);
  };

  // rewind video ten seconds on click
  const handleRewind = () => {
    var new_time = video_player.current.getCurrentTime() - 10;
    video_player.current.seekTo(new_time);
  };

  // used to show the video progress in progress bar
  const handleProgress = (e) => {
    setPlayed(e.played);
  };

  // used to show time played so far
  const handleDuration = (duration) => {
    setDuration(duration);
  };

  // Handle video end
  const handleVideoEnd = () => {
    setActiveOverlay('end'); 
  };

  // go to the next section
  const goToReview = () => {
    setActiveOverlay(null);
    dispatch(setBoth({ display: 'review', screen: 'start' }));
  };

  return (
    <>
      <div className="video-page-wrapper">

        {/* Render the welcome overlay conditionally */}
        {activeOverlay === 'welcome' && (
          <VideoWelcomeOverlay
            language={language}
            returnToLobby={returnToLobby}
            startVideo={startVideo}
          />
        )}

        {/* Render the pause overlay conditionally */}
        {activeOverlay === 'pause' && (
          <VideoPauseOverlay
            language={language}
            returnToLobby={returnToLobby}
            resumePlay={handlePlay}
            restartVideo={restartVideo}
          />
        )}

        <ReactPlayer
          className="react-player fixed-bottom"
          ref={video_player}
          url={video_url}
          width="100vw"
          height="100vh"
          controls={false}
          muted={isMuted}
          playing={isPlaying && activeOverlay === null} // Control playback based on welcome overlay visibility
          onEnded={handleVideoEnd}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onClick={() => {
            if (isPlaying) {
                handlePause();  // Pause the video and show the pause overlay
            } else {
                handlePlay();  // Resume the video and hide the pause overlay
            }
        }}
        />

        <div className="video-control-panel">
          {/* show play or pause button based off of if the video is playing or not */}
          {isPlaying ? (
            <button onClick={handlePause} className={`player-buttons`}>
              <img src={pauseButton} alt="Next Arrow" />
            </button>
          ) : (
            <button onClick={handlePlay} className={`player-buttons`}>
              <img src={playButton} alt="Next Arrow" />
            </button>
          )}
         

          <button
            onClick={handleRewind}
            className={`player-buttons margin-left-spacer`}
          >
            <img src={rewindButton} alt="Next Arrow" />
          </button>

          <progress max={1} value={played} />

          <div className={'time-elapsed-wrapper'}>
            <Duration seconds={duration * played} className={'time-elapsed'} />
          </div>
        </div>

        {/* show slide out panel based on if the video is over or not */}
        {activeOverlay === 'end' && (
            <CSSTransition
              in={activeOverlay === 'end'}
              timeout={500}
              classNames={{ enter: 'panel-enter', exit: 'panel-exit' }}
            >
              <VideoEndOverlay
                language={language}
                restartVideo={restartVideo}
                goToReview={goToReview}
              />
            </CSSTransition>
        )}
      </div>
    </>
  );
}

// animation resources
// https://blog.shahednasser.com/how-to-animate-components-entrance-and-exit-in-react/
// https://reactcommunity.org/react-transition-group/css-transition

// controls bar:
// https://github.com/CookPete/react-player/blob/HEAD/src/demo/App.js
// https://github.com/cookpete/react-player/tree/d86f0e09ad19416d561a6c1c1702f97f91c450d6
// https://cookpete.com/react-player/

export default VideoDisplay;
