import React from 'react';
import '../assets/style/components/loader.scss';
import shield from '../assets/images/tervuren-shield-white.png';

export default function Loader() {
  return (
    <div className="loader-overlay">
      <div className="inner-wrapper">
        <div className="spinner-wrapper">
          <div className="spinner" />
        </div>
        <div className="loader-center">
          <img src={shield} alt="Tervuren Shield" />
        </div>
      </div>
    </div>
  );
}
