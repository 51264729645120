// LanguageActions.js
import { GetLanguagesService } from '../../services/LanguageServices';
import {
  setActiveLanguage,
  addNewLanguage,
  setLanguageConnectionResponse,
  setLanguagesLoaded,
} from '../reducers/LanguageReducer';

// LanguageActions.js
export const getAllLanguagesAction = () => {
  return async (dispatch, getState) => {
    try {
      console.log('Starting getAllLanguagesAction'); // Debug log
      dispatch(setLanguageConnectionResponse(''));

      const state = getState();
      const location_lang = state.location?.preferred_language;
      console.log('location_lang:', location_lang);

      const languages = await GetLanguagesService();
      console.log('Languages received from service:', languages); // Debug log

      if (!languages || !Array.isArray(languages)) {
        console.error('Invalid language data received');
        dispatch(setLanguagesLoaded()); // Make sure we still set loading to false
        return;
      }

      // Add languages to store
      for (const lang of languages) {
        if (lang && lang.code) {
          dispatch(addNewLanguage(lang));
          console.log(`Added language: ${lang.code}`);
        }
      }

      // Get updated state to check languages were added
      const updatedState = getState();
      console.log(
        'Updated language list:',
        updatedState.language.languages_list
      );

      // Set active language
      if (location_lang) {
        dispatch(setActiveLanguage(location_lang));
        console.log(`Set active language to: ${location_lang}`);
      } else {
        dispatch(setActiveLanguage('en'));
        console.log('Set default language to: en');
      }

      // Finally set loading to false
      console.log('Setting languages loaded');
      dispatch(setLanguagesLoaded());
    } catch (error) {
      console.error('Error in getAllLanguagesAction:', error);
      dispatch(setLanguageConnectionResponse(error));
      dispatch(setLanguagesLoaded()); // Make sure we set loading to false even on error
    }
  };
};

export const SetCommonLanguageAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    const trainees_list = state.trainees?.trainee_list || [];
    const location_lang = state.location?.preferred_language;

    const languageCount = trainees_list.reduce((count, trainee) => {
      const lang = trainee.preferred_language;
      if (lang) {
        count[lang] = (count[lang] || 0) + 1;
      }
      return count;
    }, {});

    let mostFrequentLanguage = location_lang;
    let maxCount = 0;

    for (const [language, count] of Object.entries(languageCount)) {
      if (count > maxCount) {
        mostFrequentLanguage = language;
        maxCount = count;
      } else if (count === maxCount) {
        mostFrequentLanguage = location_lang;
      }
    }

    if (mostFrequentLanguage) {
      dispatch(setActiveLanguage(mostFrequentLanguage));
    }
  };
};
