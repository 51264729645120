import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './components/Pages/Main';
import Logout from './components/Pages/Logout';

// Add other imports if needed, like the training lobby component if it differs.

function AppRoutes({ signOut, user, loadedUser, setLoadedUser }) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Main
            user={user}
            loadedUser={loadedUser}
            setLoadedUser={setLoadedUser}
          />
        }
      />
      <Route path="/logout" element={<Logout signOut={signOut} />} />

      {/* Add the training-lobby route */}
      <Route
        path="/training-lobby"
        element={
          <Main
            user={user}
            loadedUser={loadedUser}
            setLoadedUser={setLoadedUser}
          />
        }
      />

      {/* Add the location-selection route if it's not defined elsewhere */}
      <Route
        path="/location-selection"
        element={
          <Main
            user={user}
            loadedUser={loadedUser}
            setLoadedUser={setLoadedUser}
          />
        }
      />
    </Routes>
  );
}

export default AppRoutes;
