import ApplicationLogo from '../ApplicationLogo';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '../Modal';
import CheckInIFrame from '../Modals/CheckInIFrame';
import { useState } from 'react';

const LeftPanel = ({ children }) => {
  const language = useSelector((state) => state.language.active_language);
  const location_id = useSelector((state) => state.user.location_id);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    localStorage.removeItem('outOfDate-' + location_id);
    localStorage.removeItem('checkedIn-' + location_id);
    localStorage.removeItem('traineeData-' + location_id);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="left-panel">
        <ApplicationLogo className="yellow-popout-logo" alt="Popout Logo" />
        <div className="text-wrapper">
          <h2 className="welcome-heading">
            {language.welcome_text} TERVUREN
            <span className="trademark">TM</span> {language.welcome_text_2}
          </h2>
          <h4 className="welcome-text">{language.bottom_text}</h4>

          <div className="link-wrapper">
            <Link to="/logout">{language.logout}</Link>
            <br />
            <a
              href="https://www.tervuren.app/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              tervuren.app
            </a>
            <a
              href="https://www.tervuren.app/terms-conditions/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Tervuren {language.terms_and_conditions}
            </a>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <CheckInIFrame />
      </Modal>
    </>
  );
};

export default LeftPanel;
