import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GetPossibleTraineesAction } from '../../redux/actions/TraineeActions';
import { useState } from 'react';
import LoadingButton from '../LoadingButton';

export default function WhosTraining() {
  const language = useSelector((state) => state.language.active_language);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(true); // track if the phone number is empty or not

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); // for form control
  


  const onSubmit = (data) => {
    // if it made it here, the form already validated through react hook form validation

    // set up trainee object to send to api to look up possible trainees
    let trainee_object = {};
    if (data.first_name !== '' && data.last_name !== '') {
      trainee_object.first_name = data.first_name;
      trainee_object.last_name = data.last_name;
      trainee_object.location_id = user.location_id;
    }
    if (data.phone !== '') {
      trainee_object.phone = data.phone;
    }
    if (data.company !== '') {
      trainee_object.company = data.company;
    }
    dispatch(GetPossibleTraineesAction(trainee_object, user));
  }; // [end] submit function


  // once phone number is filled out, the add trainee button is able to be used
  const onChange = (e) => {
    if (e.target.value.length >= 7) {
      setIsEmpty(e.target.value.trim() === '');
    } else {
      setIsEmpty(true);
    }
  };

  return (
    <>
      <div className="whos-training-wrapper">
        <h2 className="training-heading">
          {language.whos_training_welcome_text}
        </h2>
        <p className="training-text">
          {language.whos_training_welcome_subtext}
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
          <div className={`half-width ${errors['phone'] ? 'has-error' : ''}`}>
            <label htmlFor="phone">{language.form_phone} *</label>
            <input
              type="phone"
              name="phone"
              id="phone"
              autoComplete="off"
              onInput={onChange}
              placeholder={`${language.form_phone_placeholder}`}
              {...register('phone', {
                required: language.required_phone,
                minLength: {
                  value: 10,
                  message: language.required_phone_min,
                },
                maxLength: {
                  value: 18,
                  message: language.required_phone_max,
                },
              })}
            />
            {errors.phone && (
              <p className="error-message">{errors.phone.message}</p>
            )}
          </div>
          <div className={`half-width ${errors['company'] ? 'has-error' : ''}`}>
            <label htmlFor="company">{language.form_company}</label>
            <input
              type="text"
              id="company"
              autoComplete="off"
              placeholder={`${language.form_company_placeholder}`}
              {...register('company', {
                required: false,
                minLength: {
                  value: 3,
                  message: language.required_company_min,
                },
                maxLength: {
                  value: 65,
                  message: language.required_company_max,
                },
              })}
            />
            {errors.company && (
              <p className="error-message">{errors.company.message}</p>
            )}
          </div>
        </div>
        
        <div className="row">
          <div
            className={`half-width ${errors['first_name'] ? 'has-error' : ''}`}
          >
            <label htmlFor="first_name">{language.form_first_name}</label>
            <input
              type="text"
              id="first_name"
              autoComplete="off"
              placeholder={`${language.form_first_name_placeholder}`}
              {...register('first_name', {
                required: false,
                minLength: {
                  value: 3,
                  message: language.required_first_name_min,
                },
                maxLength: {
                  value: 65,
                  message: language.required_first_name_max,
                },
              })}
            />
            {errors.first_name && (
              <p className="error-message">{errors.first_name.message}</p>
            )}
          </div>
          <div
            className={`half-width ${errors['last_name'] ? 'has-error' : ''}`}
          >
            <label htmlFor="last_name">{language.form_last_name}</label>
            <input
              type="text"
                id="last_name"
                autoComplete="off"
              placeholder={`${language.form_last_name_placeholder}`}
              {...register('last_name', {
                required: false,
                minLength: {
                  value: 3,
                  message: language.required_last_name_min,
                },
                maxLength: {
                  value: 65,
                  message: language.required_last_name_max,
                },
              })}
            />
            {errors.last_name && (
              <p className="error-message">{errors.last_name.message}</p>
            )}
          </div>
        </div>

       

        <div className="form-whos-training-bottom-button-wrapper">
          <LoadingButton
            initialLabel={language.add_trainee_button || 'ADD TRAINEE'}
            onSubmit={handleSubmit(onSubmit)}
            type="submit"
            loadingLabel={language.add_trainee_button_loading || 'ADDING...'}
            extraClasses={isEmpty ? 'inactive' : ''}
          />
        </div>
      </form>
    </>
  ); //[end return]
} //[end] who's training function
