// App.js
import React, { useState, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import outputs from './amplify_outputs.json';

import '@aws-amplify/ui-react/styles.css';
Amplify.configure(outputs);

function AuthenticatedApp({ signOut, user, loadedUser, setLoadedUser }) {
  return (
    <BrowserRouter>
      <div className="App">
        <AppRoutes
          signOut={signOut}
          user={user}
          loadedUser={loadedUser}
          setLoadedUser={setLoadedUser}
        />
      </div>
    </BrowserRouter>
  );
}

function App() {
  const [loadedUser, setLoadedUser] = useState(false);

  return (
    <Authenticator
      hideSignUp
      className="body-decorator"
      onStateChange={(authState) => {
        console.log('Auth state changed:', authState);
        if (authState !== 'signedin') {
          setLoadedUser(false);
        } else {
          console.log(authState);
        }
      }}
    >
      {({ signOut, user }) => {
        // Additional check for valid user
        if (!user || !user.username) {
          return <div>Authenticating...</div>;
        }

        return (
          <AuthenticatedApp
            signOut={signOut}
            user={user}
            loadedUser={loadedUser}
            setLoadedUser={setLoadedUser}
          />
        );
      }}
    </Authenticator>
  );
}

export default App;
